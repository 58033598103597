<template>
  <div class="book-video">
    <div class="title">
      <h2>汽车的基本结构</h2>
      <span>时长：1分7秒</span>
    </div>
    <div class="book-video-info">
      视频
    </div>
    <div class="content-box">
      <p><span></span>紧扣最新教学与考试大纲</p>
      <p><span></span>驾考知识权威、专业、全面</p>
      <p><span></span>多终端数据实现实时同步</p>
      <div class="info-box">
        <img
          src="../assets/images/pic100.png"
          alt=""
        />高清视频动画资源，全新打造，全新体验!想要学习更多内容，快下载车学堂APP吧！
      </div>
      <a class="download-btn"
        ><span class="icon-cloud-download"></span>下载APP，智能同步考驾照</a
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  }
}
</script>
